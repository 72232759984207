<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="display"
                        :name="beForm[rep].display.label"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice"
                        :rules="getRules('display')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="title"
                        :name="beForm[rep].title.label"
                        label="Descrizione"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="sector_id"
                        :name="beForm[rep].sector_id.label"
                        :label="beForm[rep].sector_id.label"
                        v-model="form[rep].sector_id"
                        :options="sector_opt"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('sector_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].info.label"
                        vid="info"
                        :label="beForm[rep].info.label"
                        v-model="form[rep].info"
                        :rules="getRules('info')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_sundry_expense"
                        :name="beForm[rep].is_sundry_expense.label"
                        label="Mostra solo in movimenti vari"
                        v-model="form[rep].is_sundry_expense"
                        :options="
                          beForm[rep].is_sundry_expense.options || [
                            { value: 'N', text: 'No' },
                            { value: 'Y', text: 'Sì' },
                          ]
                        "
                        :rules="getRules('is_sundry_expense')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import CustomInputs from "@/components/form/CustomInputs";
import BaseInput from "@/components/form/BaseInput";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseSelect from "@/components/form/BaseSelect";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "treasury",
      id: this.$route.params.id,
      // #1022
      sector_opt: [],
      form: {
        treasury: {
          display: null,
          info: null,
          is_abstract: "N",
          is_system: "N",
          sector_id: null,
          status_treasury: null,
          title: null,
          is_sundry_expense: "N",
        },
      },
    };
  },
  components: {
    BaseIcon,
    CustomInputs,
    BaseInput,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$store.dispatch("auth/treasuries");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Cassa di Compagnia modificata`,
          });
          this.shortcut(
            "module.PROF.CAGG",
            null,
            "#CompanyTreasuries",
            "filterCompanyTreasury"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.sector_opt = this.$store.state.auth.sectors
        .filter((sector) => sector.is_agency.value === "N")
        .map((el) => {
          return {
            value: el.id,
            text: el.title,
          };
        });
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
